import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import '@gravity-ui/uikit/styles/fonts.css';
import '@gravity-ui/uikit/styles/styles.css';
import { ToasterComponent, ToasterProvider } from '@gravity-ui/uikit';
//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <ToasterProvider>
      <App />
      <ToasterComponent /* className="optional additional classes" */ />
    </ToasterProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//reportWebVitals(console.log);
