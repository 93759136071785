import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@gravity-ui/uikit';

const Main = lazy(() => import('./components/pages/Main'));
const CreatAd = lazy(() => import('./components/pages/CreatAd'));
const AdScreen = lazy(() => import('./components/pages/AdScreen'));

const App = () => {
    return (<>
        <ThemeProvider theme="dark">
            <div className="bazaar-root_theme_dark bazaar-root_font">
                <Router>
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <Routes>
                            <Route path="*" element={<Main />} />
                            <Route path="/CreatAd" element={<CreatAd />} />
                            <Route path="/AdScreen" element={<AdScreen />} />
                        </Routes>
                    </Suspense>
                </Router>
            </div>
        </ThemeProvider>
    </>)
};

export default App;